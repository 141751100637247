export const NOTIFICATION_IDENTIFIERS = {
  AI_ASSISTANT_INDEXING_PROGRESS: "aiAssistantIndexingProgress",
  CSV_DOWNLOAD: "csvExport",
  DROMO_UPLOAD: "dromoImport",
  PROCEDURE_ATTACH: "attachOwnOemProcedureToWorkOrder",
  PROCEDURE_FINALIZE: "finalizeOwnOemProcedure",
  PROCEDURE_PDF_DOWNLOAD: "downloadProcedurePDF",
  PROCEDURE_TEMPLATE_DUPLICATE: "duplicateOwnOemProcedureTemplate",
  SEND_EMAIL: "sendEmail",
  EMAIL_ACCOUNT_SYNCED: "emailAccountSynced",
  EMAIL_FOLDER_COUNT_SYNCED: "emailFolderCountSynced",
};
