import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { NotificationCounter } from "@shared/ui/Indicators";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useSubRoutes } from "~/constants";
import { useGetAppConfig } from "~/services/appConfig/_query";
import type { UserType } from "~/types/user";

const SubNavigation = ({
  route,
  pathname,
  user,
  parentRouteKey,
  unreadLabels,
  sidebarExpanded,
  isOverlay = false,
}: {
  route: string;
  pathname: string;
  user: UserType;
  parentRouteKey: string;
  unreadLabels: { openTickets: number; myTickets: number };
  sidebarExpanded: boolean;
  isOverlay?: boolean;
}) => {
  const { appConfig } = useGetAppConfig();
  const subRoutes = useSubRoutes(route, user, appConfig?.features);
  const { messages } = useIntl();

  return subRoutes || [].length ? (
    <div
      className={`h-full overflow-hidden ${
        !sidebarExpanded && !isOverlay ? "w-0" : "w-56xl"
      } ${
        pathname === "/app/work/scheduler"
          ? ""
          : "transition-all ease-in-out duration-300"
      }`}
    >
      <div
        className={`h-full w-56xl ease-in-out flex flex-col py-2xl px-lg space-y-sm ${
          !isOverlay
            ? "border-solid border-primary border-r border-0"
            : "shadow-alert"
        }`}
      >
        {isOverlay && (
          <Label size={LABEL_SIZES.MEDIUM}>
            {messages?.menus?.[parentRouteKey]?.title}
          </Label>
        )}
        {(subRoutes || []).map((subRoute) => (
          <div key={subRoute.titleKey}>
            {/* @ts-ignore */}
            <Link to={subRoute.route}>
              <div
                className={`flex cursor-pointer justify-between p-sm space-x-sm items-center rounded-md ${
                  pathname.startsWith(subRoute.route)
                    ? "bg-accent"
                    : "hover:bg-accent"
                }`}
              >
                <div className="flex space-x-sm">
                  {pathname.startsWith(subRoute.route) ? (
                    <subRoute.iconActive className="h-xl w-xl" />
                  ) : (
                    <subRoute.icon className="h-xl w-xl text-secondary stroke-secondary" />
                  )}
                  {pathname.startsWith(subRoute.route) ? (
                    <Label size={LABEL_SIZES.MEDIUM} color="text-brand">
                      {
                        messages.menus?.[parentRouteKey]?.SUB_MENUS?.[
                          subRoute.titleKey
                        ]
                      }
                    </Label>
                  ) : (
                    <BodyText
                      size={BODY_TEXT_SIZES.SMALL}
                      color="text-secondary"
                    >
                      {
                        messages.menus?.[parentRouteKey]?.SUB_MENUS?.[
                          subRoute.titleKey
                        ]
                      }
                    </BodyText>
                  )}
                </div>
                {unreadLabels?.[subRoute.routeKey] ? (
                  <NotificationCounter
                    count={unreadLabels[subRoute.routeKey]}
                  />
                ) : null}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default SubNavigation;
